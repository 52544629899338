@use '../../styles/variables';
@use '../../styles/mixins';

.create-page {
    .page-content-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        background-color: variables.$content-background-color;
        align-items: center;

        .drop-down-wraps {
            padding: 8px;
            width: 130px;
        }

        .page-content-title {
            font-size: 16px;
            font-family: variables.$title-font-family;
            padding-left: 10px;
        }
    }
    
    .create-form {
        background-color: variables.$content-background-color;
        padding: 30px;

        .preview-container {
            margin-bottom: 20px;

            .invalid-question-preview {
                background-color: rgb(255, 255, 255);
                min-height: 300px;
                display: flex;
                font-size: 40px;
                font-family: variables.$title-font-family;
                color: variables.$error-color;
                justify-content: center;
                align-items: center;
            }
        }

        .monospace-input {
            font-size: 12px;
            font-family: monospace;
        }

        .create-button {
            background-color: variables.$secondary-accent-color;
        }

        .editable-block-field, .uneditable-block-field {
            max-width: 100%;
        }
    }

    .help {
        background-color: variables.$content-background-color;
        padding: 30px;
    }
}

.form-with-spacing {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
