@use '../../styles/mixins';
@use '../../styles/variables';
@use '_display';

.display-defaults {
    width: 100%;
    display: inline-block;
}

.display {
    @include display.display;
    padding: 20px;

    &[variant="default"] {
        @include display.default-display;
    }

    &[variant="raised"] {
        @include display.raised-display;
    }

    &[variant="inset"] {
        @include display.inset-display; 
    }

    &[variant="header"] {
        @include display.header-display;
    }

    .title {
        font-family: variables.$title-font-family;
        font-size: 30px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2); 
        margin-bottom: 20px;
    }
}