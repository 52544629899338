@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';
@use '../../components/DisplayPositioner/display-positioner';

@mixin header-text {
    font-size: 10px;
    font-family: variables.$monospace-font-family;
    color: variables.$less-prominent-dark-font-color;
}

$message-border-radius: 8px;

.chat-page {
    @include mixins.exact-height(variables.$page-content-min-height);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .topic-messages {
        border-left: variables.$border-stack;
        border-right: variables.$border-stack;
        display: flex;
        flex-direction: column;
        max-width: 800px;
        width: 100%;
        height: 100%;
        background-color: variables.$content-background-color;
        overflow: hidden;

        border-top: variables.$border-stack;
        border-bottom: variables.$border-stack;
        border-radius: display.$border-radius;
        height: calc(100% - display-positioner.$display-gap);

        .message-page-header {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            align-items: center;
            padding: 20px 30px 20px 30px;
            background-color: variables.$primary-accent-color;
            border-bottom: 1px solid color.scale(variables.$border-color, $alpha: -50%);

            * {
                color: variables.$light-font-color;
            }

            .message-title {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                margin-bottom: 10px;
            }
        }

        .messages {
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 0px 10px 20px 10px;
            overflow-y: auto;
            @include mixins.hide-scrollbar;

            .welcome-message {
                width: 100%;
                display: flex;
                justify-content: center;
                
                &>p {
                    margin: 40px 0px 40px 0px;
                    max-width: 600px;
                    text-align: center;
                    color: variables.$less-prominent-dark-font-color;
                }
            }

            &>span,
            &>div {
                font-size: 0.3rem;
            }

            .message-container {
                display: flex;
                margin-right: 50px;
                min-width: 310px;
                max-width: 100%;
                margin-bottom: 1px;

                .profile-icon {
                    height: 50px;
                    width: 50px;
                    border-radius: 50%;
                }

                .message-content {
                    margin-top: 15px;
                    min-width: 200px;
                    overflow: hidden;
                    padding: 0px 10px 10px 10px;
                    border-radius: 0 $message-border-radius $message-border-radius $message-border-radius;
                    outline: 1px solid variables.$secondary-accent-color;
                    box-sizing: border-box;

                    .message-header {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;
                        margin-top: 5px;
                        width: 100%;

                        .author-name {
                            display: flex;
                            align-items: start;
                            
                            &>a {
                                @include header-text;
                                margin-top: auto;
                            }
                        }
                    }

                    .message-body {
                        margin-top: 7px;

                        &>p {
                            font-size: 14px;
                        }
                    }

                    .created-at {
                        @include header-text;
                    }
                }
            }

            .message-user {
                margin-right: 0;
                margin-left: 50px;
                flex-direction: row-reverse;

                .message-content {
                    border-radius: $message-border-radius 0 $message-border-radius $message-border-radius;
                    outline: 1px solid variables.$primary-accent-color;
                    background-color: variables.$content-background-color;

                    .message-header {
                        flex-direction: row-reverse;
                    }

                    .message-body {
                        text-align: right;
                        border-radius: $message-border-radius 0 $message-border-radius $message-border-radius;
                    }

                    .created-at {
                        margin-right: auto;
                        margin-left: 0;
                    }
                }
            }
        }

        /* Chat input */
        .message-box {
            display: grid;
            grid-template-columns: calc(100% - 60px) 60px;
            grid-template-rows: 1fr;
            grid-template-areas: "input send-button";
            padding: 10px;
            box-shadow: -5px -5px 10px rgba(0, 0, 0, 0.2);
            background-color: variables.$header-color;

            .message-input {
                grid-area: input;
                height: 100%;
                @include display.default-display;
                padding: 20px;
                resize: none;
                outline: none;
                font-family: variables.$font-family;
                font-size: 14px;
            }

            .message-send-button {
                all: unset;
                grid-area: send-button;
                height: 60px;
                width: 60px;
                font-size: 30px;
                color: variables.$border-color;
                @include mixins.hoverable;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                    transform: scale(1.1);
                }
            }
        }
    }
}