@use '../../styles/variables';
@use '../../styles/mixins';

@mixin toggle-off {}

@mixin toggle-on {
    background-color: variables.$secondary-accent-color;

    &>div {
        margin-left: 50%;
    }
}

.toggle-container {
    width: 40px;
    height: 25px;

    input.toggle {
        width: 100%;
        height: 100%;
        min-height: 0;
        margin: 0px;
        opacity: 0;
        padding: 0px;
        border: 0px;

        &:hover {
            cursor: pointer;
        }

        &:checked {
            & + .toggle-appearance {
                @include toggle-on;
            }
        }

        &:before {
            content: "";
            width: 100%;
            height: 100%;
        }
    }

    .toggle-appearance {
        width: 100%;
        height: 100%;
        position: relative;
        bottom: calc(100% + 4px);
        pointer-events: none;
        @include toggle-off;
        display: flex;
        flex-direction: row;
        justify-content: start;
        transition-duration: 500ms;
        border-radius: 100px;
        background-color: rgba(0, 0, 0, 0.2);
        padding: 5px;
        
        &>div {
            transition-duration: 500ms;
            width: 50%;
            background-color: variables.$content-background-color;
            @include mixins.strong-shadow;
            border-radius: 100%;
            margin-left: 0px;
        }
    }
}