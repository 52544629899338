
@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';
@use '../../components/DisplayPositioner/display-positioner';

@mixin column-flex {
    display: flex;
    flex-direction: column;
}

.leaderboard-container {
    @include column-flex;
    align-items: center;
    width: 100%;
    padding: 20px;

    .leaderboard-positioner {
        width: 100%;

        .leaderboard-header {
            margin-bottom: display-positioner.$display-gap;
            align-items: center;

            h2 {
                font-size: 18px;
            }

            .header-with-toggle {
                display: flex;
                align-items: center;
                gap: 5px;
            }
        }

        .leaderboard-user-previews {
            @include display.default-display;
            padding: 0px;
            margin-bottom: 30px;

            .leaderboard-user-preview {
                width: 100%;
                height: 50px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                border-bottom: 1px solid color.scale(variables.$border-color, $alpha: -20%);
                padding: 0px 20px 0px 10px;

                &:last-child {
                    border-bottom: 0px;
                }

                .leaderboard-value {
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    font-size: 20px;
                }

                .leaderboard-ranking-user-icon {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .leaderboard-ranking {
                        font-family: variables.$title-font-family;
                        font-size: 20px;
                        background-color: rgba(0, 0, 0, 0.2);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                        justify-content: center;
                        height: 100%;
                        @include mixins.exact-width(50px);
                    }

                    .leaderboard-user-icon {
                        height: 100%;
                        margin-left: 5px;
                    }
                }
            }
        }

        .filter-options {
            display: flex;
            padding: 0px 20px 10px 20px;
        }

        .top-three {
            width: 100%;

            .top-three-header {
                width: 100%;
                height: 100px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                align-items: center;
            }

            .top-three-card-container {
                display: grid;
                margin: 0px 40px 0px 40px;
                $grid-gap: 30px;
                $column-width: calc(100% / 3 - $grid-gap * 2 / 3);
                gap: $grid-gap;
                grid-template-columns: $column-width $column-width $column-width;
                width: calc(100% - 80px);
                align-items: end;
                margin-bottom: display-positioner.$display-gap;

                @media screen and (max-width: 700px) {
                    $grid-gap: 18px;
                    $column-width: calc(100% / 3 - $grid-gap * 2 / 3);
                    gap: $grid-gap;
                    grid-template-columns: $column-width $column-width $column-width;
                }

                .top-three-filled {
                    @include mixins.hoverable;
                }

                .top-three-card {
                    @include display.default-display;
                    padding: 0px;
                    background-color: variables.$content-background-color;
                    max-width: 100%;
                    height: 300px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    align-items: center;

                    &[variant="rank-1"] {
                        height: 300px;

                        .top-three-info {
                            .top-three-rank {
                                background-color: variables.$primary-accent-color;
                                font-size: 35px;
                            }
                        }
                    }

                    &[variant="rank-2"] {
                        height: 260px;
                    }

                    &[variant="rank-3"] {
                        height: 240px;
                    }

                    .top-three-icon {
                        width: 100%;
                        height: 70px;
                        overflow: hidden;
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                    }

                    .top-three-user {
                        height: 100%;
                        justify-content: start;
                        padding-top: 20px;
                        @include column-flex;
                        gap: 10px;
                    }

                    .top-three-info {
                        text-align: center;
                        width: 100%;

                        .top-three-rank {
                            font-size: 30px;
                            background-color: variables.$secondary-accent-color;
                            font-family: variables.$title-font-family;
                            color: variables.$light-font-color
                        }

                        .top-three-name {
                            font-size: 20px;
                        }
                    }

                    .top-three-stats-box {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;

                        .top-three-stats-box-number {
                            font-size: 19px;
                            font-weight: 750;
                            padding-bottom: 3px;
                        }

                        .top-three-stats-box-text {
                            font-size: 12px;
                            color: variables.$less-prominent-dark-font-color
                        }
                    }
                }
            }
        }
    }
}