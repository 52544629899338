@use '../../styles/mixins';
@use '../../styles/variables';
@use '../../components/Display/display';

.search-bar-positioner {
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;

    .search-bar-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        max-width: 600px;
    }
}

.search-bar {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 20px;
    padding: 3px 15px 3px 15px;
    @include mixins.faded-outline;

    &>input {
        @include mixins.input-defaults;
        min-width: 0px;
        max-width: 100%;
        flex-grow: 2;
        padding: 5px;
    }
}

.magnifying-glass {
    display: block;
}

.search-results-positioner {
    width: 100%;
    position: relative;

    .search-results {
        @include display.default-display;
        top: 5px;
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .search-result-header:first-child {
        border-top: 0px;
    }
}

@mixin search-result {
    all: unset;
    pointer-events: all;
    display: block;
    background-color: variables.$header-color;
    padding: 5px;
}

.search-result-nothing {
    @include search-result;
}

.search-result-result {
    @include search-result;
    @include mixins.hoverable;  
}

.search-result-header {
    @include search-result;
    font-weight: bold;
    font-size: 17px;
    border-top: 1px solid rgba(0, 0, 0, 0.2);
    @include mixins.hoverable;
}