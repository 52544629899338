@use '../../styles/variables';
@use '../../styles/mixins';

.full-question-body {
    @include mixins.page-content;
    @include mixins.center-flex-row;

    .question-container {
        width: 100%;
        margin: 0px 10px 0px 10px;
        max-width: 600px;
    }

    .invalid-link {
        background-color: variables.$content-background-color;
        @include mixins.center-flex-row;
        padding: 1rem;
        border-radius: 5px;;
    }
}
