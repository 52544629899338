@use '../../styles/variables';
@use '../../styles/mixins';

.toggle {
    display: flex;
    align-items: center;
    margin-top: 4px;

    span {
        margin-right: 10px;
    }

    .toggle-container {
        margin-top: 6px;
    }
}