@use 'variables';
@use 'mixins';

* {
    box-sizing: border-box;
}

div, h2, h1, h3, p, a, label {
    font: variables.$normal-text;
    margin: 0;
    text-decoration: none;
    color: variables.$dark-font-color;
}

body, html {
    margin: 0px;
    padding: 0px;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: variables.$page-background-color;
}

.hoverable {
    @include mixins.hoverable;
}

.center-y {
    @include mixins.center-y;
}

h1 {
    font-size: 30px;
    font-weight: 700;
}

h2 {
    font-size: 24px;
}

h3 {
    font-size: 18px;
}

hr {
    border-color: variables.$border-color;
    background-color: variables.$border-color;
    color: variables.$border-color;
}

.qlist {
    display: flex;
    flex-direction: column;
    max-height: 800px;
    overflow-y: scroll;
    padding: 10px;

    &>div {
        &>* {
            @include mixins.space-vertically(10px);
        }
    }
}

.row-flex-centered {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
}

.aria-description {
    display: none;
}

.sr-only {
    position: absolute;
    left: -10000px;
    top: auto;
    width: 1px;
    height: 1px;
    overflow: hidden;
}

.page-deps-missing-error {
    width: 100%;
    min-height: variables.$page-content-min-height;
    position: fixed;
    display: flex;
    left: 0;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    color: variables.$error-color;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

h1, h2, h3 {
    font-family: variables.$title-font-family;
    font-weight: 400;
}

.code-block {
    @include mixins.code-block;
}

.monospace {
    font-family: variables.$monospace-font-family;
}
