@use '../../styles/mixins';
@use '../../styles/variables';
@use '../../components/Display/display';
@use 'sass:color';

// TODO: make text wrap

.question {
    @include display.raised-display;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: row;
    overflow: hidden;

    .question-aside {
        @include mixins.exact-width(35px);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: rgba(0, 0, 0, 0.1);
        padding: 20px 3px 20px 3px;
        margin-right: 10px;

        .question-vote {
            .question-vote-arrow {
                text-align: center;
                transition-duration: 200ms;
                
                &.up.active {
                    svg.question-vote-arrow-svg {
                        color: variables.$upvote-color;
                    }
                }

                &.down.active {
                    svg.question-vote-arrow-svg {
                        color: variables.$downvote-color;
                    }
                }
                
                svg.question-vote-arrow-svg {
                    color: variables.$less-prominent-dark-font-color;
                    transform: scale(1.5);
                    @include mixins.hoverable;
                }
            }

            .question-vote-count {
                font-size: 12px;
                font-weight: 700;
                margin: 5px 0px 5px 0px;
                text-align: center;

                @media (max-width: 600px) {
                    margin-top: auto;
                    margin-bottom: auto;
                }
            }
        }

        .small-button-container {
            height: 30px;
            width: 30px;
            margin-top: 0.5rem;
            @include mixins.center-flex-row;
            padding: 2px;
            border-radius: 50%;
            transition: background-color 0.25s ease-in-out;
            border: none;
            background-color: rgba(0, 0, 0, 0);

            &:hover {
                cursor: pointer;
                background-color: rgba(0, 0, 0, 0.1);
            }
        }
    }

    .question-body {
        flex-grow: 1;
        padding: 20px 30px 20px 0px;

        .question-top-bar {
            min-height: 50px;
            display: flex;
            flex-direction: column;
            border-bottom: 1px solid color.scale(variables.$border-color, $alpha: -50%);

            .question-title-pfp-positioner {
                height: 50px;
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .question-title {
                    font-size: 25px;
                    font-family: variables.$title-font-family;
                }
            }

            .question-meta-positioner {
                flex-direction: row;
                display: flex;
                justify-content: space-between;
                height: 10px;

                &>*{
                    font-family: monospace;
                    font-size: 10px;
                }
            }

            .tags {
                display: flex;
                flex-direction: row;
                padding: 10px 0px 10px 0px;

                .tag {
                    background-color: variables.$secondary-accent-color;
                    color: variables.$light-font-color;
                    border-radius: 100px;
                    font-size: 12px;
                    padding: 5px 10px 5px 10px;
                    @include mixins.space-horizontally(5px);
                }
            }
        }

        .fitg-body, .mc-body {
            padding: 20px 0px 20px 0px;
        }

        .fitg-body {
            $line-spacing: 5px;
            $line-height: 26px;

            .fitg-elements {
                $font-height: 18px;

                .fitg-input {
                    @include mixins.deep-inset-shadow;
                    width: 40px;
                    border-radius: 100px;
                    // TODO: fix color
                    background-color: rgb(150, 150, 150);
                    transition-duration: 200ms;
                    height: $line-height;
                    margin-bottom: $line-spacing;
                    // TODO: check this is robust
                    display: inline-block;
                    @include mixins.space-horizontally(5px);
                    position: relative;
                    top: 5px;

                    &[variant="over"] {
                        background-color: rgb(50, 50, 50);
                    }
                }

                .fitg-text {
                    line-height: $line-height;
                    font-size: $font-height;
                    // TODO: check this is robust
                    display: inline;
                    word-wrap: break-word;
                }

                .fitg-block {
                    display: inline-block;
                    @include mixins.space-horizontally(5px);
                    line-height: 16px;
                }
            }

            .fitg-blocks {
                margin-top: 30px;
                width: 100%;
                display: flex;
                justify-content: center;
                flex-wrap: wrap;
                gap: 7px;
            }
            
            .fitg-block {
                border-radius: 100px;
                padding: 5px 10px 5px 10px;
                min-width: 40px;
                text-align: center;
                background-color: variables.$content-background-color;
                height: $line-height;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-bottom: $line-spacing;
                @include mixins.faded-outline;
                @include mixins.no-select;

                &:hover {
                    @include mixins.strong-shadow;
                    cursor: pointer;
                }

                &[variant="over"] {
                    @include mixins.deep-inset-shadow;
                    filter: brightness(0.5);
                }
            }
        }

        .mc-body {
            $font-height: 18px;

            .mc-question {
                margin-bottom: 20px;
                font-size: 20px;
            }

            .mc-options {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                .mc-option {
                    transition-duration: 400ms;
                    @include mixins.faded-outline;
                    @include mixins.hoverable;
                    background-color: variables.$content-background-color;
                    $gap: 10px;
                    width: calc(50% - $gap / 2);
                    max-width: 50%;
                    text-align: center;
                    border-radius: 2px;
                    padding: 15px;
                    margin-top: $gap;

                    &:nth-child(1), &:nth-child(2) {
                        margin-top: 0px;
                    }

                    &[variant="left"] {
                        margin-right: $gap;
                    }

                    &[variant="end"] {
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}