@use '../../styles/mixins';
@use '../../styles/variables';
@use '../../components/Display/display';

.modal-positioner {
    position: fixed;
    top: 0px;
    bottom: 0px;
    z-index: 1000;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    transition-duration: 200ms;
    pointer-events: none;
    flex-direction: column;
    padding: 0px 40px 0px 20px; // TODO: this is to account for scrollbar width -- but it shouldn't be 
                                // an issue anyway
    
    .modal-container {
        max-width: 100%;
        @include display.raised-display;
        
        .close-modal-button-positioner {
            bottom: 0px;
            border-radius: 20px;
            @include mixins.exact-height(0px);
            @include mixins.exact-width(100%);
            position: relative;
            left: 100%;
    
            .close-modal-button {
                all: unset;
                $diameter: 40px;
                @include mixins.exact-width($diameter);
                @include mixins.exact-height($diameter);
                @include mixins.hoverable;
                border: 2px solid variables.$content-background-color;
                transform: translateX(-$diameter / 2 - 4px) translateY(-$diameter / 2 + 4px);
                background-color: variables.$rubbish-red;
                color: variables.$light-font-color;
                border-radius: 50px;
                text-align: center;
                font-size: 18px;
                line-height: calc($diameter + 1px);
                font-weight: 800;
                position: absolute;
                pointer-events: all;
            }
        }

        .modal-title {
            pointer-events: all;
            border-radius: display.$border-radius display.$border-radius 0px 0px;
            overflow: hidden;

            &>h1 {
                padding: 10px 40px 10px 40px;
                background-color: variables.$primary-accent-color;
                color: variables.$light-font-color;
            }
        }
    
        .modal {
            pointer-events: all;
            padding: variables.$modal-padding;

            &[variant="has-title"] {
                border-radius: 0px 0px display.$border-radius display.$border-radius;
            }
        }
    }
}