@use '../../styles/mixins';
@use '../../styles/variables';
@use 'sass:color';

.block-fields {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;

    .uneditable-block-field {
        background-color: variables.$content-background-color;
        padding: 8px 20px 8px 20px;
        border-radius: 5px;
        @include mixins.faded-border;
        transition-duration: 600ms;    
    }

    .editable-block-field-container {
        display: flex;
        flex-direction: row;

        .editable-block-field {
            @include mixins.input-defaults;
            min-width: 0px;
            @include mixins.faded-border;
            border-radius: 5px 0px 0px 5px;
            padding: 8px 0px 8px 20px;
        }

        .delete {
            all: unset;
            @include mixins.hoverable;
            background-color: variables.$error-color;
            color: variables.$light-font-color;
            font-weight: 1000;
            border-radius: 0px 5px 5px 0px;
            padding: 5px;
        }
    }

    .add-new-block {
        all: unset;
        border-radius: 1000px;
        @include mixins.exact-width(20px);
        @include mixins.exact-height(20px);
        align-self: center;
        background-color: variables.$secondary-accent-color;
        text-align: center;
        line-height: 0px;
        width: 100%;
        font-weight: 1000;
        color: variables.$light-font-color;
        padding: 5px;
        @include mixins.hoverable;
    }
}
