@use 'sass:color';

// easily adjustable site-wide color palette
// for a light-mode scheme, make the colors here dark if the column under `lm` 
// says 'dark' and vice versa for dark-mode schemes
//                                          lm | dm
//                                       ------+------
$coolor-1            : #1e1e20; //      dark | light
$coolor-2            : #5a864f; //      mid  | mid
$coolor-3            : #d54e4e; //  mid-dark | mid-light

// is this scheme a light-mode or dark-mode scheme
$brightness-mode: dark;

// EXAMPLE DARK
$brightness-mode: dark;
$coolor-1: #1e1e20; 
$coolor-2: #5a864f; 
$coolor-3: #d54e4e;

// EXAMPLE LIGHT
$brightness-mode: light;
$coolor-1: #d1ecff;
$coolor-2: #3f34b9;
$coolor-3: #d53c34;

// ------------------------------------------------------------------------------------

@function light-dark($lval, $dval) {
    @if $brightness-mode == light {
        @return $lval;
    } 
    @return $dval;
}

// THE color of educat.dev
$primary-accent-color: $coolor-2; 
$less-ouchy-primary-accent-color: color.scale($primary-accent-color, $lightness: -15%);
// THE (other) color of educat.dev (for buttons etc.)
$secondary-accent-color: $coolor-3; 
// default font-color
// [light] near-black
//  [dark] near-white
$dark-font-color: light-dark(#0f0f0f, #fff);
$less-prominent-dark-font-color: color.scale($dark-font-color, $alpha: -40%);
// font color used when the background of the component is NOT $content-background-color
$light-font-color: light-dark(#fff, #ffffff);
$less-prominent-light-font-color: color.scale($light-font-color, $alpha: -40%);
// the background color of the site (this is present on basically every page)
$page-background-color: $coolor-1;
$content-background-color: light-dark(#fff, #2e2e2e);
$redder-content-background-color: light-dark(#ea9898, #5a1414);
$greener-content-background-color: light-dark(#98ea9f, #1b5a14);
// duh
$upvote-color: #4cc666;
$downvote-color: #bd3f3f;
// `color` for errors
$error-color: #a80000;
$header-color: color.scale($content-background-color, $lightness: -3%);
$border-color: light-dark(#000, #fff);
$border-stack: 2px solid $border-color;
// NEW COLOR NAMES (these are not to be changed to adjust site-wide styling)
$almost-black: rgb(45, 45, 45);
$rubbish-red: rgb(180, 38, 38);

// The font stack
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,700;0,800;0,1000;1,700;1,1000&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Sora:wght@200;300;600;800&family=Tilt+Warp&display=swap');
$font-family: 'Roboto', sans-serif;
$title-font-family: 'Tilt Warp', sans-serif;
$normal-text: 15px $font-family;
$monospace-font-family: monospace;

$header-height: 50px;
$page-content-min-height: calc(100vh - $header-height);
$modal-padding: 20px;

// TODO: get rid of
$delete-btn-bakground: rgba(255, 3, 3, 0.159);
$delete-btn-bakground-hover: #ff00003c;