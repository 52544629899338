@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use '../DropDown/drop-down-variables';

.header, .header-height {
    @include mixins.exact-height(variables.$header-height);
}

@mixin header-element {
    height: 100%;
    background-color: variables.$header-color;
    // @include mixins.hoverable; // TODO: make this work but not on children
}

.header {
    @include mixins.weak-shadow;
    width: 100%;
    background-color: variables.$header-color;
    border-top: 2px solid variables.$primary-accent-color;
    position: fixed;
    z-index: 10000; // TODO: sort out online thing
}

.navigation-drop-down {
    width: 100%;
    max-width: 150px;
    @include header-element;
    
    .navigation-drop-down-wraps {
        height: 100%;
        display: flex;
        flex-direction: row;
        width: 100%;
        align-items: center;
        margin-right: 10px;
    }
}

.login-signup-button {
    font-size: 1rem;
    font-family: variables.$title-font-family;
    @include mixins.hoverable;
    @include mixins.center-y;
    @include header-element;
    padding: 0px 10px 0px 10px;
    min-width: fit-content;
}

.login-signup-button-reactive {
    display: flex;
    flex-direction: column;
    
    &>* {
        font-family: variables.$title-font-family;
    }

    &>hr {
        height: 1px;
    }
}

.header-element-positioner {
    $padding: 10px;
    padding: 0px $padding 0px $padding;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &>.logo-link, &>.user-preview {
        @include header-element;
    }

    &>*:first-child {
        justify-self: start;
    }

    &>*:last-child {
        justify-self: end;
    }
}

.user-drop-down {
    min-width: 150px;
    @include mixins.center-y;
    @include header-element;
}

.radio-positioner {
    width: 100%;
    display: flex;
    justify-content: center;
}

.logo-link {
    text-decoration: none;
    color: variables.$dark-font-color;

    .logo-link:visited {
        color: variables.$dark-font-color;
    }
}

.login-signup-form-title-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-bottom: 15px;
    
    .login-signup-form-title-text {
        font-family: variables.$title-font-family;
        font-size: 30px;
        color: variables.$primary-accent-color;
    }
}

.tall-hr {
    margin-top: 10px;
    margin-bottom: 10px;
}
.field-container {
    display: flex;
    flex-direction: row;

    &>input {
        min-width: 0px;
    }
}

.login-signup-image {
    max-width: 400px;
    padding: 30px;
    width: 100%;
}

.login-signup-form {
    padding: 10px 30px 10px 30px;
}

.status-options {
    padding: 10px;

    .status-option {
        @include mixins.hoverable;
        @include mixins.space-vertically(5px);
        border-radius: 5px;
        padding: 0.5rem;
        background-color: variables.$content-background-color;
        width: 100%;
        display: flex;
        overflow: visible;
        align-items: center;
        
        .status-icon {
            @include mixins.exact-width(12px);
            @include mixins.exact-height(12px);
            border-radius: 100px;
            margin-right: 5px;
        }
    }
    
    .status-tick {
        width: 40%;
        align-content: center;
        display: flex;
        justify-content: center;
    }
}

.password-strength-indicator-bars {
    display: flex;
    flex-direction: column;
    width: 20px;
    align-items: center;
    gap: 2px;
    justify-content: center;
    height: calc(4px * 3 + 2px * 2);

    .password-strength-indicator-bar {
        width: 16px;
        height: 4px;
        border-radius: 100px;

        &.weak {
            background-color: rgb(200, 81, 81);
        }

        &.moderate {
            background-color: rgb(213, 143, 81);
        }

        &.good {
            background-color: rgb(74, 148, 74);
        }
    }
}