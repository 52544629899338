@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';

.account-user-display-container {
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-direction: row;
    align-items: center;
    margin-bottom: 50px;
}

.edit-pfp {
    @include mixins.center-y;
    justify-content: center;
    flex-direction: column;

    .pfp {
        width: 200px;
        height: 200px;
        border-radius: 50%;
        margin-top: 50px;
    }

    .edit-pfp-button-positioner {
        position: relative;
        width: 0px;
        height: 0px;

        .edit-pfp-button {
            $diameter: 65px;
            $position: 90px;
            position: relative;
            bottom: $diameter;
            left: calc($diameter / 2);
            width: $diameter;
            height: $diameter;
            border-radius: 100px;
            background-color: variables.$secondary-accent-color;
            border: 3px solid variables.$page-background-color;
            @include mixins.center-flex-row;
            @include mixins.hoverable;
    
            // TODO: change this to fontawesome
            img {
                margin: calc($diameter / 8);
                width: 75%;
                height: 75%;
            }
        }
    }

}

.account-info-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 50px 0 0 50px;
    padding: 20px;
    @include display.default-display;
    @include mixins.weak-shadow;

    .account-info-field {
        @include mixins.space-vertically(10px);

        .account-info-field-label {
            font-family: variables.$title-font-family;
            color: variables.$primary-accent-color;
            font-size: 13px;
        }
        
        .account-info-field {
            width: 100%;
            font-size: 1.4rem;
        }
    }
}

.status-message {
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
}   

.edit-pfp-modal {
    @include mixins.center-y;
    flex-direction: column;
    max-width: 60vw;
    padding: 10px;
    
    .edit-pfp-modal-body {
        @include mixins.center-y;
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-bottom: 20px;
        transform: scale(1);

        [type=radio] { 
            position: absolute;
            opacity: 0;
            width: 0;
            height: 0;
          }

        [type=radio] + img {
            $diameter: 150px;
            width: $diameter;
            height: $diameter;
            margin: 20px 20px 0px;
            cursor: pointer;
            transition-duration: 200ms;
            filter: brightness(0.5);
            transition-property: filter;
            border-radius: 4px;
        }
        
        [type=radio]:checked + img {
            outline: 3px solid variables.$secondary-accent-color;
            filter: brightness(1);
        }
    }       
}

.delete-account-modal {
    @include mixins.center-y;
    flex-direction: column;
    width: 100%;
    max-width: calc(min(600px, 100vw - (20px * 2 + 40px)));
    //                                  ^^^^^^^^ default modal padding
    // TODO: build in to modal
    
    .delete-account-modal-body {
        justify-content: space-evenly;
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

@media (max-width: 1050px) {
    .edit-pfp-modal {
        max-width: 80vw;

        .edit-pfp-modal-body {
        
            [type=radio] + img {
                $diameter: 100px;
                width: $diameter;
                height: $diameter;
                margin: 10px 10px 0px;
                cursor: pointer;
            }
        }
    }
}

.account-form-display-container {
    @include mixins.space-vertically(10px);
    
    .Collapsible {
        @include display.raised-display;
    }
        
    .account-form-trigger {
        all: unset;
        cursor: pointer;
        $padding: 15px;
        width: calc(100% - $padding * 2);
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include mixins.hoverable;
        padding: 10px $padding 10px $padding;
        transition-duration: 500ms;

        &[variant="opened"] {
            background-color: variables.$less-ouchy-primary-accent-color;
            border-radius: display.$border-radius display.$border-radius 0px 0px;
        }
    
        &[variant="closed"] {
            background-color: variables.$almost-black;
            border-radius: display.$border-radius;
        }

        .account-form-trigger-title {
            color: variables.$light-font-color;
            font-family: variables.$title-font-family;
            font-size: 20px;
        }
    }

    .account-form-display {
        background-color: variables.$content-background-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        @include mixins.space-vertically(30px);
        border-radius: 0px 0px 5px 5px;
        padding: 20px;
    }
}
