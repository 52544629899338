@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';
@use 'sass:color';

@mixin column-flex {
    display: flex;
    flex-direction: column;
}

.topics-page {
    @include column-flex;
    align-items: center;
    width: 100%;
    padding: 20px;
    
    .topics-header {
        width: 100%;

        &>h1 {
            padding: 40px 40px 0px 40px;
            color: variables.$primary-accent-color;
            font-weight: 1000;
        }

        .topics-subheader {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    .sort-by-container {
        background-color: variables.$content-background-color;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .title-container {
            display: flex;
            flex-direction: column;
            padding-left: 10px;

            .topics-title {
                font-size: 18px;
            }

            .create-appeal-button {
                all: unset;
                @include mixins.hoverable;
                color: color.scale(variables.$secondary-accent-color, $alpha: -30%);
                text-decoration: underline;
                font-size: 12px;
            }
        }
    }

    .topic-list {
        @include display.default-display;
        @include column-flex;
        overflow: hidden;
        width: 100%;
    
        .stats-box {
            padding-left: 20px;
            padding-right: 20px;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        
            .stats-box-number {
                font-size: 20px;
                color: variables.$dark-font-color;
                font-weight: 750;
                padding-bottom: 3px;
            }
        
            .stats-box-text {
                font-size: 10pt;
                color: variables.$less-prominent-dark-font-color;
            }
        }
    
        .topic-summary {
            all: unset;
            @include mixins.hoverable;
            padding: 20px;
            gap: 10px;
            border-bottom: variables.$border-stack;
            background-color: variables.$content-background-color;
            width: 100%;
        
            &:last-child {
                border-bottom: none;
            }
    
            .topic-icon {
                border-radius: 5px;
                padding: 0px;
                @include mixins.exact-width(50px);
                @include mixins.exact-height(50px);
            }
    
            .description {
                color: color.scale(variables.$dark-font-color, $alpha: -50%);
            }
            
            &[variant="wide"] {
                display: flex;
                flex-direction: row;
                align-items: center;
            
                .name-description {
                    width: calc(100% - 450px);
                }
        
                .name {
                    font-size: 25px;
                    font-weight: 600;
                }
            }
            
            &[variant="narrow"] {
                display: flex;
                flex-direction: row;
    
                .name {
                    margin-bottom: 4px;
                }
    
                .stats {
                    display: flex;
                    flex-direction: row;
                    gap: 5px;
                    margin-bottom: 10px;
    
                    * {
                        font-size: 12px;
                        color: color.scale(variables.$dark-font-color, $alpha: -20%);
                    }
                }
            }
        }
    }    
}

.topic-appeal-modal {
    background-color: variables.$content-background-color;
    padding: variables.$modal-padding;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 300px;
    pointer-events: all;

    .proceed-to-appeal-button {
        all: unset;
        @include mixins.buttonize;
        background-color: variables.$primary-accent-color;
    }
}