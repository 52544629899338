@use '../../styles/variables';
@use 'sass:color';

.radio {
    display: flex;
    justify-content: space-between;
    flex-direction: row;

    .radio-button {
        border-bottom: 1px solid color.scale(variables.$dark-font-color, $alpha: -40%);
        margin: 5px;
        font-size: 15px;
        // transition-duration: 200ms;

        &:hover {
            cursor: pointer;
        }

        &[variant="active"] {
            border-bottom: 2px solid variables.$dark-font-color;
        }
    }
}