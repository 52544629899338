@use '../../styles/mixins';
@use '../../styles/variables';
@use 'sass:color';

.labelled-field-label-container {
    .label-text {
        font-weight: 700;
        margin-bottom: 4px;
        display: block;
    }

    .label-subtext {
        color: color.scale(variables.$dark-font-color, $alpha: -30%);
        font-size: 12px;
        margin-bottom: 4px;
    }
}

.labelled-field-container {
    width: 100%;

    .field-container {
        all: unset;
        background-color: variables.$content-background-color;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        @include mixins.faded-outline;
        border-radius: 5px;
        
        &[variant="error"] {
            outline-color: variables.$error-color;
        }
        
        .field-inner {
            $padding: 20px;
            all: unset;
            min-width: 0px;
            width: calc(100% - $padding * 2);
            padding: 10px $padding 10px $padding;

            &.with-field-icon {
                padding-left: 0px;
            }

            &.monospace {
                font-family: variables.$monospace-font-family;
                font-size: 12px;
            }
        }
    }

    .error {
        $height: 10px;
        @include mixins.exact-height(calc($height * 2));
        transition-duration: 200ms;
        font-size: $height;
        line-height: $height;
        color: rgb(240, 20, 20);
        user-select: none;
        width: 100%;
        padding: 5px 20px 5px 20px;
        -webkit-user-select: none;
        
        &[variant="hidden"] {
            opacity: 0%;
        }
    }
}

