@use '../../styles/variables';
@use '../../styles/mixins';
@use 'sass:color';

.user-icon {
    @include mixins.center-y;
    background-color: transparent;
    
    &.clickable {
        transition-duration: 200ms;
    
        &:hover {
            cursor: pointer;
            background-color: color.scale(
                variables.$content-background-color, 
                $alpha: -70%, 
                $lightness: -50%
            );
            border-radius: 5px;
        }
    }

    .pfp-status {
        height: 100%;
        padding: 5px;
    
        .pfp {
            // TODO: make this always square
            border-radius: 100px;
            height: 100%;
        }
    
        .status {
            $diameter: 13px;
            position: relative;
            bottom: $diameter;
            left: calc(100% - $diameter);
            width: $diameter;
            height: $diameter;
            border-radius: 100px;
            border: 2px solid variables.$header-color;
        }
    }

    .username-reputation {
        margin-right: 5px;
    
        .username {
            font-size: 15px;
            font-weight: bold;
        }
        
        .reputation {
            font-size: 10px;
        }
    }
}


