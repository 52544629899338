@use 'sass:color';
@use 'variables';

@keyframes jiggle-red-keyframes {
    0% { 
        transform: rotate(0deg);
    }
    10% { 
        transform: rotate(-1deg);
        background-color: variables.$redder-content-background-color;
    }
    20% { 
        transform: rotate(1deg);
        background-color: variables.$redder-content-background-color;    
    }
    30% { 
        transform: rotate(0deg);
        background-color: variables.$redder-content-background-color;
    }
    100% { 
        transform: rotate(0deg);
        background-color: variables.$content-background-color;
    }
}

.jiggle-red {
    animation-name: jiggle-red-keyframes;
    animation-duration: 800ms;
}

@keyframes spin-green-keyframes {
    0% {
        transform: scale(1);
    }
    20% {
        transform: scale(1.07);
        background-color: variables.$greener-content-background-color;
    }
    50% {
        transform: scale(0.965);
        background-color: variables.$greener-content-background-color;
    }
    100% {
        transform: scale(1);
    }
}

.spin-green {
    animation-name: spin-green-keyframes;
    animation-duration: 800ms;
}