@use '../../styles/variables';
@use '../../styles/mixins';

.feed-header {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: end;
    background-color: variables.$content-background-color;

    .filters {
        display: flex;
        flex-direction: row;
        align-items: center;

        .advanced-filters-button {
            all: unset;
            color: variables.$less-prominent-dark-font-color;
            @include mixins.hoverable;
        }
    }

    .sort-by {
        font-size: 15px;
        background-color: variables.$content-background-color;
        padding: 5px;
        border-radius: 100px;

        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }
}