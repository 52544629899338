@use '../../styles/variables';

.bad-request-error-display-positioner {
    height: variables.$page-content-min-height;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .bad-request-error-display {
        h2 {
            color: variables.$error-color;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            margin-bottom: 10px;
            text-align: center;
            
            .error-code {
                font-size: 20px;
                font-family: variables.$monospace-font-family;
                margin-right: 10px;
            }
            
            .message {
                font-size: 30px;
                font-family: variables.$title-font-family;
            }
        }
        
        img {
            width: 100vw;
            max-width: 600px;
            border-radius: 5px;
        }
    }
}
