@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use '../DropDown/drop-down-variables';

.drop-down-list {
    display: flex;
    flex-direction: column;

    & > .drop-down-list-el {
        @include mixins.hoverable-without-filter;
        @include mixins.center-y;
        background-color: variables.$content-background-color;
        $border-color: drop-down-variables.$drop-down-border-color;
        width: 100%;
        padding: 10px 20px 10px 20px;
        border-bottom: 1px solid $border-color;
        font: variables.$normal-text;
        font-size: 13px;
        overflow: visible;

        &:last-child {
            border-bottom: 0px;
        }

        .icon {
            width: 1.3em;
            display: inline-block;
            margin-right: 1em;
        }

    }

    &>.link {
        color: variables.$dark-font-color;
        text-decoration: none;
    }
}