@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';

.home {
    width: 100%;

    .home-header {
        width: 100%;
        min-height: 650px;
        background-color: color.scale(variables.$primary-accent-color, $lightness: 20%);
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .home-header-content {
            width: 100%;
            max-width: 700px;
            min-width: 400px;
            padding: 20px;

            .home-title {
                color: variables.$light-font-color;
                font-weight: 1000;
                font-size: 50px;
                padding: 20px 60px 20px 60px;
                text-align: center;
                transform: scaleX(1.2);
                font-family: variables.$title-font-family;
            }

            .home-description {
                text-align: center;
                color: variables.$light-font-color;
                font-weight: 600;
            }

            .home-login-signup-container {
                display: flex;
                width: 100%;
                justify-content: center;
                flex-direction: row;
                padding: 25px;

                .home-login-button,
                .home-signup-button {
                    all: unset;
                    border-radius: 100px;
                    padding: 10px 20px 10px 20px;
                    font-size: 15px;
                    max-width: 70px;
                    width: 100%;
                    text-align: center;
                    @include mixins.space-horizontally(20px);
                    @include mixins.hoverable;
                }

                .home-login-button {
                    background-color: variables.$content-background-color;
                }

                .home-signup-button {
                    background-color: variables.$almost-black;
                    color: variables.$light-font-color;
                }
            }
        }
    }

    .home-info-list {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}