@use '../../styles/variables';

@keyframes wagging-hand-keyframes {
    0% { transform: rotate(-10deg) }
    100% { transform: rotate(10deg) }
}

.report-report-page {
    .report {
        .from-to-positioner {
            height: 60px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            .from-to {
                height: 100%;

                .user-icon-container {
                    height: calc(100%);
                }
            }

            .wagging-hand {
                height: 25px;
                animation: wagging-hand-keyframes;
                animation-timing-function: ease-in-out;
                animation-direction: alternate;
                animation-iteration-count: infinite;
                animation-duration: 500ms;
                color: variables.$error-color;
            }
        }

        .report-details-header {
            margin: 20px 0px 10px 0px;
        }

        .report-details {

        }
    }
}