@use 'sass:color';
@use '../../../styles/mixins';
@use '../../../styles/variables';


.team-footer-container {
    @include mixins.center-y;
    flex-direction: column;
    justify-content: center;
    background-color: variables.$almost-black;
    padding: 50px;

    .team-footer-title {
        font-family: variables.$title-font-family;
        font-size: 2rem;
        margin-bottom: 50px;
        color: variables.$light-font-color;
    }
}

.team-members-container {
    @include mixins.center-y;
    flex-wrap: wrap;
    justify-content: center
}

.team-member-container {
    @include mixins.center-y;
    flex-direction: column;
    margin: 0 50px 20px 50px;
    text-align: center;

    .team-member-image {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        margin-bottom: 10px;
    }

    .team-member-name {
        font-size: 1.5rem;
        font-weight: 800;
        margin-bottom: 5px;
        color: variables.$light-font-color;
    }

    .team-member-role {
        font-weight: 300;
        font-style: italic;
        color: variables.$less-prominent-light-font-color;
    }
}