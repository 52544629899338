@use '../../styles/variables';
@use '../../styles/mixins';
@use 'sass:color';

.share-modal-body {
    text-align: center;
}

.share-elements {
    margin-top: 1rem;
    border-radius: 5px;
    @include mixins.faded-outline;
    gap: 10px;
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;

    .paperclip {
        margin: 0px 10px 0px 20px;
        filter: opacity(0.5);
        transform: scale(1.5);
    }

    .share-link {
        font-family: variables.$monospace-font-family;
        color: color.scale(variables.$dark-font-color, $alpha: -30%);
        overflow-x: scroll;
        scrollbar-width: none;
        
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .copy-btn {
        all: unset;
        @include mixins.hoverable;
        width: 70px;
        padding: 15px;
        color: variables.$light-font-color;
        text-align: center;
        border-radius: 5px;
        margin: 5px;
        
        &[variant="not-copied"] {
            background-color: variables.$secondary-accent-color;
        }

        &[variant="copied"] {
            background-color: variables.$upvote-color;
        }
    }
}