@use '../../styles/variables';
@use '../../styles/mixins';

.usericon-container {
    height: 70px;
    width: 20%;
    overflow-x: auto;
}

.view-report-link {
    @include mixins.hoverable;
}

.report-summary {
    padding: 0px 20px 20px 20px;
    
    .report-id {
        font-family: variables.$monospace-font-family;
        font-size: 9px;
        padding-top: 6px;
        padding-bottom: 6px;
        width: 100%;
        overflow-x: hidden;
        color: variables.$less-prominent-dark-font-color;
    }

    .report-summary-details {
        display: flex;
        flex-direction: row;
        gap: 10px;
        align-items: start;
        overflow: auto;
        // justify-content: space-between;

        .user-summary-container {
            height: variables.$header-height;
            min-width: 110px;
        }
    }
}

.reports-page {
    @include mixins.page-content;
    height: 90vh;
    overflow-y: scroll;
    padding: 0px 20px 0px 20px;

    .reports-positioner{
        width: 70%;
        margin: auto;
        min-width: 550px;
    }
    .reports-header {
        width: 100%;
        padding: 40px;
        @include mixins.center-flex-column;
        &>h1 {
            color: variables.$dark-font-color;
            font-family: variables.$title-font-family;
            font-size: 40px;
        }
    }

    .reports-container {
        @include mixins.center-flex-column;
        .reports-map-div {
            @include mixins.center-flex-column;
            width: 100%;
            min-width: 500px;
            max-width: 800px;
        }
    }
}

.full-reports-page {
    width: 100%;
    @include mixins.center-flex-column;
    .display-positioner{
        height: 80vh;
        @include mixins.center-flex-column;
        width: 1000px;

        .full-report{
            display: flex;
            height: 70vh;

            .full-reason{
                overflow-wrap: break-word;
            }
        }
    }

    
    .full-report-header {
        width: 100%;
        text-align: center;
        font-family: variables.$title-font-family;
        margin-bottom: 1rem;
    }
    
    .report-details {
        width: 70%;
        border-radius: 5px 0 0 5px;

        .report-subtitle {
            font-size: 20px;
            font-weight: 420;
        }

        &>p {
            margin-bottom: 0.5rem;
        }
    }

    .report-controls {
        width: 30%;
        border-left: 2px solid rgba(221, 207, 207, 0.468);
        max-width: 400px;
        min-width: 200px;
        border-radius: 0 5px 5px 0;
        @include mixins.center-flex-column;

        .delete-btn {
            @include mixins.buttonize;
            background-color: rgba(255, 3, 3, 0.159);
            margin-bottom: 1rem;
            color: variables.$error-color;
            width: 70%;
            
            transition: background-color 0.25s ease-in-out;
            &:hover{
                cursor: pointer;
                background-color:#ff00003c;
            }
        }

        .share-btn{
            @include mixins.buttonize;
            background-color: rgba(0, 128, 255, 0.159);
            color: rgb(10, 131, 131);
            &:hover {
                cursor: pointer;
                background-color: rgba(0, 128, 255, 0.235);
            }
        }
    }

}

