@use '../../styles/variables';
@use '../../styles/mixins';

.submit-topic-appeal-page {
    .submit-topic-appeal-title {
        font-size: 18px;
    }

    .submit-message-display {
        &.success {
            background-color: variables.$greener-content-background-color;
        }

        &.failure {
            background-color: variables.$redder-content-background-color;
        }
    }
    
    .icon-url-field-icon {
        .validity-indicator {
            border-radius: 1000px;
            @include mixins.exact-width(8px);
            @include mixins.exact-height(8px);
            margin: 10px;
        
            &[variant="valid"] {
                background-color: variables.$upvote-color;
            }
        
            &[variant="invalid"], &[variant="maybe-invalid"] {
                background-color: variables.$downvote-color;
            }
        }
    
        img {
            @include mixins.exact-width(80px);
            padding: 0px;
        }        
    }
}
