@use '../../styles/mixins';
@use '../../styles/variables';

// TODO: make this continue displaying as we hover over
//       the content 
.hover-drop-down {
    &:hover {
        .hover-drop-down-content-positioner {
            opacity: 100%;
            visibility: visible;
        }
    }

    .hover-drop-down-content-positioner {
        width: 100%;
        @include mixins.exact-height(0px);
        transition-duration: 300ms;
        pointer-events: none;
        opacity: 0%;
        visibility: hidden;
    }
}

.hover-drop-down-display-positioner {
    width: 100%;
    @include mixins.exact-height(0px);
    display: flex;
    flex-direction: row;

    &[variant="center"] {
        justify-content: center;
    }

    &[variant="left"] {
        justify-content: start;
    }

    &[variant="right"] {
        justify-content: end;
    }
    
    .hover-drop-down-display {
        position: absolute;
        background-color: variables.$content-background-color;
        @include mixins.weak-shadow;
    }
}
