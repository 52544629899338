@use '../../styles/mixins';
@use '../../styles/variables';

$border-radius: 5px; 

@mixin display {
    // transform: skew(5deg) rotate(2.5deg);
    // background-image: linear-gradient(rgb(255, 255, 255), rgb(255, 255, 237));
    border-radius: $border-radius;
    background-color: variables.$content-background-color;
}

@mixin default-display {
    @include display;
    @include mixins.faded-outline;
}

@mixin raised-display {
    @include display;
    @include mixins.weak-shadow;
    @include mixins.faded-outline;
}

@mixin inset-display {
    @include display;
    @include mixins.weak-inset-shadow;
}

@mixin header-display {
    @include display;
    @include mixins.faded-outline;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}