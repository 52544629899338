@use '../../styles/mixins';
@use '../../styles/variables';

$feed-width: 650px;

.feed-container {
    $padding: 20px;
    padding: $padding;
    @media (max-width: calc($feed-width - $padding)) {
        padding: $padding 0px $padding 0px;
    }
    max-width: $feed-width;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
}

.feed {
    width: 100%;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    
    .questions {
        width: 100%;
    }

    &>*, .question {
        @include mixins.space-vertically(20px);
    }

    .loading-gif {
        height: 40px;
    }
}
