@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';
@use '../../components/DisplayPositioner/display-positioner';
@use 'sass:color';

$medium-screens: 1100px;
$small-screens: 730px;

$user-summary-width: 250px; // TODO: this is bad CSS (I think... I hate it)
$gap: display-positioner.$display-gap;

.users-page {
    @include mixins.page-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: $gap;

    .users-page-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 1100px;
        justify-content: center;
        gap: $gap;

        &>.panels {
            display: flex;
            flex-direction: column;
            width: calc(100% - $user-summary-width - $gap);
            gap: $gap;

            &>div:first-child {
                width: 100%;
                display: flex;
                flex-direction: row;
                gap: $gap;
            }
        }
    }

    .users-page-content-medium {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        gap: $gap;

        &>.panels {
            gap: $gap;
            display: flex;
            flex-direction: column;
            width: calc(100% - $user-summary-width - $gap);
        }
    }

    .users-page-content-small {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: $gap;
        
    }

    .users-panel-container {
        @include display.default-display;
        @include mixins.faded-outline;
        @include mixins.weak-shadow;
        padding: 30px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .users-panel-heading {
            font-size: 30px;
            font-family: variables.$title-font-family;
            font-weight: 400;
            margin-bottom: 15px;
        }

        .topic-list {
            display: flex;
            flex-direction: column;
            gap: 12px;
            padding: 10px 0 10px 0;
        }
    }

    .topic-name {
        text-decoration: none;
        font-size: 12px;
        font-weight: 600;
        padding: 10px;
        border-radius: 500px;
        display: flex;
        color: variables.$light-font-color;
        background-color: variables.$secondary-accent-color;

        &:hover {
            opacity: 0.9;
        }
    }

    .user-summary {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: auto;
        padding: 2em;
        gap: 60px;

        @media (min-width: $small-screens) {
            min-height: 690px;
            width: $user-summary-width;
            gap: 20px;
        }

        .pfp-container {
            position: relative;
            display: flex;
            justify-content: center;
            width: 200px;
            height: 200px;
            margin: 0 auto 0 auto;

            .pfp {
                width: 200px;
                height: 200px;
                border-radius: 100%;
            }
        
            .status-aligner {
                position: absolute;
                bottom: 5px;
                right: 15px;
            }

            .status {
                width: 40px;
                height: 40px;
                border-radius: 100%;
                border: 4px solid variables.$content-background-color;
            }
        }
        
        .user-summary-subheading {
            color: variables.$less-prominent-dark-font-color;
        }
        
        .user-summary-separator {
            @include mixins.weak-shadow;
            border-radius: 2px;
            height: 2px;
            width: 100%;
            background-color: variables.$secondary-accent-color;
        }
        
        .user-summary-text {
            color: variables.$less-prominent-dark-font-color;
            font-size: 18px;
        }
        
        .user-follow-button {
            all: unset;
            @include mixins.buttonize;

            &.following {
                background-color: variables.$secondary-accent-color;
            }

            &.follow {
                background-color: variables.$primary-accent-color;
            }
        }

        .left-right {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .report-button {
                all: unset;
                color: darkred;
                border-radius: 100%;
                text-align: center;
                line-height: 0px;
                @include mixins.hoverable;
            }
        }
    }

    .users-card-preview-container {
        width: 100%;
        $card-preview-upvotes-width: 40px;
        $card-preview-date-width: 75px;

        .users-card-preview {
            $padding: 10px;
            color: variables.$dark-font-color;
            display: flex;
            height: 50px;
            display: flex;
            align-items: center;
            padding: $padding;
            gap: 5px;
            justify-content: stretch;
            border-bottom: 1px solid variables.$border-color;
            @include mixins.hoverable;
            background-color: variables.$content-background-color;
            width: 100%;
    
            .card-preview-upvotes {
                border-radius: 4px;
                min-width: $card-preview-upvotes-width;
                max-width: $card-preview-upvotes-width;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                height: 100%;
                padding: 5px;
                font-size: 12px;
                color: variables.$light-font-color;
                margin-right: 10px;
    
                &[variant="positive"] {
                    background-color: variables.$upvote-color;
                }
    
                &[variant="negative"] {
                    background-color: variables.$downvote-color;
                }
            }
            
            .card-preview-title {
                height: 100%;
                max-width: calc(100% - $card-preview-upvotes-width - $card-preview-date-width - $padding * 2);
                flex-grow: 10000;
                display: flex;
                align-items: center;
                    
                &>div {
                    width: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
    
            .card-preview-date {
                min-width: $card-preview-date-width;
                max-width: $card-preview-date-width;
                font-size: 12px;
                color: color.scale(variables.$dark-font-color, $alpha: -50%);
                text-align: right;
            }
        }

    }

    .user-stat {
        font-size: 24px;
    }

    .user-stat-tag {
        font-size: 14px;
        color: variables.$less-prominent-dark-font-color;
        padding-left: 5px;
    }

    .community-stats {
        padding-top: 10px;
        list-style-type: none;
        padding: 0;
        margin: 0;

        li {
            padding-top: 20px;
        }

        .follows-container {
            display: flex;
            justify-content: space-between;
        }
    }

    .user-recent {
        width: 100%;
        height: 100%;
        padding: 3em;

        .card-preview-container {
            display: flex;
            flex-direction: column;
        }
    }

    .user-topic-data {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
}

.report-modal {
    @include mixins.center-y;
    flex-direction: column;
    width: 100%;
    max-width: calc(min(600px, 100vw - (20px * 2 + 40px)));

    .report-modal-body {
        justify-content: space-evenly;
        flex-wrap: wrap;

        .report-modal-title {
            font-family: variables.$title-font-family;
            color: variables.$primary-accent-color;
            margin-bottom: 10px;
        }
    }
}

.report-modal-reason {
    @include mixins.center-y;
    flex-direction: column;
    margin: 15px 0 5px 0;
    align-items: center;

    &>textarea {
        @include mixins.faded-outline;
        border-radius: 10px;
        padding: 7px 12px 7px 12px;
        margin: 10px 0;
        text-align: left;
        resize: vertical;
        width: 100%;
        overflow: auto;
        border: none;

    }

    .error-message {
        color: variables.$error-color;
        font-size: 12px;
        margin: 5px 0 0 0;
    }
}

.error-message {
    color: variables.$error-color;
    font-size: 12px;
    margin: 5px 0 0 0;
    text-align: center;
}

.see-all {
    margin-top: 10px;
    text-decoration: underline;
}