@use './variables';

/**
 * contains some commonly used mixins
 */

@mixin weak-shadow {
    box-shadow: 5px 5px 10px rgba(25, 25, 25, 0.2);
}

@mixin strong-shadow {
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
}

@mixin weak-inset-shadow {
    box-shadow: inset 5px 5px 10px rgba(25, 25, 25, 0.2);
}

@mixin deep-inset-shadow {
    box-shadow: inset 2px 2px 3px rgba(0, 0, 0, 0.3);
}

@mixin center-y {
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

@mixin center-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin center-flex-row {
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin buttonize {
    $padding: 10px;
    text-align: center;
    padding: $padding;
    width: calc(100% - $padding * 2);
    border-radius: 20px;
    font-weight: 700;
    color: variables.$light-font-color;
    @include hoverable;
}

@mixin no-select {
    -webkit-user-select: none;
    user-select: none;
}

@mixin max-100 {
    max-width: 100%;
    max-height: 100%;
}

@mixin hoverable {
    transition-duration: 100ms;
    // background-color: rgba(0, 0, 0, 0);

    &:hover {
        // background-color: rgba(0, 0, 0, 0.1);
        filter: brightness(0.85);
        cursor: pointer;
    }
}

@mixin hoverable-without-filter {
    transition-duration: 100ms;
        // background-color: rgba(0, 0, 0, 0);
    
        &:hover {
            background-color: rgb(218, 218, 218);
            cursor: pointer;
        }
}

@mixin faded-outline {
    outline: variables.$border-stack;
}

@mixin space-horizontally($amount) {
    margin: 0px $amount 0px $amount;

    &:first-child {
        margin-left: 0px;
    }

    &:last-child {
        margin-right: 0px;
    }
}

@mixin space-horizontally-with-padding($amount) {
    padding: 0px $amount 0px $amount;

    &:first-child {
        padding-left: 0px;
    }

    &:last-child {
        padding-right: 0px;
    }
}

@mixin space-vertically($amount) {
    margin: $amount 0px $amount 0px;

    &:first-child {
        margin-top: 0px;
    }

    &:last-child {
        margin-bottom: 0px;
    }
}

@mixin exact-width($amount) {
    width: $amount;
    max-width: $amount;
    min-width: $amount;
}

@mixin exact-height($amount) {
    height: $amount;
    max-height: $amount;
    min-height: $amount;
}

@mixin faded-border {
    border: variables.$border-stack;
}

@mixin page-content {
    min-height: variables.$page-content-min-height;
}

@mixin code-block {
    @include faded-outline;
    font-family: monospace;
    background-color: variables.$almost-black;
    color: variables.$light-font-color;
    padding: 10px;
    font-size: 14px;
    border-radius: 4px;
    box-shadow: inset 3px 3px 3px rgba(0, 0, 0, 0.2);
    margin: 10px 0px 10px 0px;
}

@mixin light-code-block {
    @include code-block;
    background-color: rgb(252, 252, 252);
    color: variables.$dark-font-color;
}

@mixin placeholder-color($color) {
    &::placeholder,
    &::-webkit-input-placeholder,
    &:-ms-input-placeholder,
    &:-moz-placeholder {
        color: $color;
    }
}

@mixin input-defaults {
    all: unset;
    // TODO: make this actually work
    @include placeholder-color(variables.$less-prominent-dark-font-color);
}

@mixin hide-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}