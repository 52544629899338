@import '../../styles/variables';

.logo {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    transition-duration: 200ms;

    * {
        font-size: 30px;
        font-weight: 800;
    }

    div {
        display: flex;
        flex-direction: row;
        align-items: center;

        .logo-text {
            display: inline-block;
            font-size: 20px;
        }
    }
}