@use 'sass:color';
@use '../../../styles/mixins';
@use '../../../styles/variables';

$padding: 100px;

.home-info-container, .home-info-alt-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 100px 0px 100px 0px;
}

.home-info-container {
    background-color: variables.$content-background-color;
}

.home-info-alt-container {
    background-color: color.scale(variables.$content-background-color, $lightness: -10%);
}

.home-info-body {
    @include mixins.center-y;
    justify-content: center;
    width: 100%;
    max-width: 900px;

    .home-info-text {
        padding: 20px;

        .home-info-title {
            font-family: variables.$title-font-family;
            font-size: 50px;
            padding: 0px 30px 30px 30px;
        }

        .home-info-description {
            font-weight: 400;
            color: variables.$less-prominent-dark-font-color;
            padding: 0px 20px 0px 20px;
            line-height: 21px;
        }
    }
}

@media (max-width: 600px) {
    .home-info-body {
        flex-direction: column;
        max-width: 400px;
    }
}

.home-info-graphic {
    height: 350px;
    width: 350px;
}