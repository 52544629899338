@use '../../styles/variables';
@use '../../styles/mixins';

.submit-button-positioner {
    $padding: 10px;
    width: 100%;
    padding: 10px 0px 10px 0px;

    .submit-button {
        all: unset;
        text-align: center;
        padding: $padding;
        width: calc(100% - $padding * 2);
        border-radius: 20px;
        font-weight: 700;
        color: variables.$light-font-color;
        @include mixins.hoverable;
        background-color: variables.$primary-accent-color;

        &.invert {
            background-color: variables.$secondary-accent-color;
        }

        &:disabled {
            background-color: lightgrey;
            cursor: default;
        }
    }
}