@use '../../styles/variables';
@use '../../styles/mixins';
@use '../../components/Display/display';
@use 'sass:color';

.topic-appeal-display {
    @include display.default-display;
    padding: 5px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    .body-positioner {
        display: grid;
        grid-template-columns: calc(100% - 90px) 90px;
        gap: 5px;

        .body {
            display: flex;
            flex-direction: column;
            gap: 5px;
        }
    }

    .top-bar {
        height: 10px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        .id {
            font-size: 10px;
            color: variables.$less-prominent-dark-font-color;
            font-family: variables.$monospace-font-family;
        }
        
        .username {
            .submitted-by {
                color: variables.$less-prominent-dark-font-color;
            }
         
            font-size: 12px;
        }
    }

    .icon-with-name {
        height: 50px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        
        .icon {
            height: 100%;
            border-radius: 5px;
        }

        .name {
            font-family: variables.$title-font-family;
            font-size: 18px;
        }
    }

    .subtitle {
        cursor: pointer;
        width: 100%;
        padding: 5px 10px 5px 10px;
        border-radius: 5px 5px 0 0;
        border-bottom: 1px solid color.scale(variables.$border-color, $alpha: -20%);
        
        &:hover {
            background-color: rgba(0, 0, 0, 0.1);
        }
    }

    .Collapsible p {
        color: variables.$less-prominent-dark-font-color;
    }

    .manage-buttons {
        display: flex;
        flex-direction: column;
        justify-content: end;

        button.approve {
            all: unset;
            @include mixins.buttonize;
            background-color: variables.$primary-accent-color;
            max-height: 20px;

            &.approved {
                background-color: variables.$less-prominent-dark-font-color;
            }
        }
    }
}