@use 'sass:color';
@use '../../styles/variables';
@use '../../styles/mixins';
@use 'display-positioner';

// TODO: feed needs to use display-positioner.space-displays

@mixin space-displays {
    display: flex;
    flex-direction: column;
    gap: display-positioner.$display-gap;
}

.page-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.center {
        min-height: calc(variables.$page-content-min-height);
        justify-content: center;
    }

    .display-positioner {
        width: 100%;
        display: grid;
        grid-gap: display-positioner.$display-gap;
        padding: display-positioner.$display-gap;
        
        @media (min-width: 750px) {
            &[variant="with-aside"] {
                max-width: 900px;
                grid-template-columns: 66% calc(34% - display-positioner.$display-gap);
            }

            &[variant="without-aside"] {
                max-width: 800px;
                grid-template-columns: 100%;
            }
            
            .aside-content {
                @include space-displays;
            }
        }

        @media (max-width: 750px) {
            grid-template-columns: 100% 0px;

            .aside-content {
                display: none;
            }
        }
        
        .main-content.default-gap {
            @include space-displays;
        }
    }
}

.sticky-content {
    @include space-displays;
    position: sticky;
    top: calc(variables.$header-height + display-positioner.$display-gap); 
}