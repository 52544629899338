@use 'sass:color';
@use '../../styles/mixins';
@use '../../styles/variables';
@use 'drop-down-variables';

$transition-duration: 300ms;

.drop-down {
    @include mixins.center-y;
    @include mixins.hoverable;
    padding: 0px 10px 0px 10px;
    outline: 1px solid rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;

    // TODO: change this to a .svg so we can set the color properly
    .arrow {
        transform: rotate(270deg);
        transition-duration: $transition-duration;
        max-height: 12px;
    }
}

.drop-down-children {
    @include mixins.weak-shadow;
    border-radius: 5px;
    position: absolute;
    right: 0px;
    top: calc(100% + 5px);
    background-color: variables.$header-color;
    outline: 1px solid drop-down-variables.$drop-down-border-color;
    max-height: 0px;
    transition-duration: $transition-duration;
    overflow: hidden;
    overflow-y: scroll;
    z-index: 1000;
}

.default-wraps {
    min-height: 40px;
    padding: 5px;
    @include mixins.center-y;
}

.menu-positioner {
    position: relative;
    width: 100%;
}

.drop-down-sort-by-wraps {
    padding: 3px 8px 3px 8px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    line-height: 10px;
    color: color.scale(variables.$dark-font-color, $alpha: -30%);
}