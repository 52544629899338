@use '../../styles/variables';
@use '../../styles/mixins';

.topic-aside-stats {
    color: variables.$less-prominent-dark-font-color;
}

.topic-aside-create-question-button {
    background-color: variables.$secondary-accent-color;
}

.topic-aside-description {
    margin-top: 14px;
}

.topic-follow-button {
    all: unset;
    margin-top: 10px;
    @include mixins.buttonize;
    background-color: variables.$secondary-accent-color;

    &.invert {
        background-color: variables.$primary-accent-color;
    }
}