@use '../Display/display';
@use '../DisplayPositioner/display-positioner';
@use '../../styles/mixins';
@use '../../styles/variables';

.fancy-paginated {
    .turn-page-buttons-positioner {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;
        
        .turn-page-buttons-container {
            max-width: 300px;
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            gap: 4px;

            .turn-page-button {
                all: unset;
                @include mixins.hoverable;
                height: 30px;
                width: 30px;
                border-radius: 100%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                background-color: transparent;
    
                &:hover {
                    background-color: rgba(0, 0, 0, 0.1);
                }

                &.current {
                    background-color: variables.$secondary-accent-color;
                    color: variables.$light-font-color;

                    &:hover {
                        background-color: variables.$secondary-accent-color;
                    }
                }
            }
        }
    }
}